const cookiesUtil = require('../cookiesUtil');

function codigoPostalValido(codigoPostal) {
    var regex = /^\d{5}$/;
    return regex.test(codigoPostal);
}

const isAlimerkaOnlinePage = () => {
    return $('.page').find('.alimerka-responde-container').length;
};

const isFolletoPage = () => {
    return $('.page').find('.folleto-page').length;
};

/**
 * Displays the login window if the user has not selected a store or it is not an Alimerka online page.
 */
const showLoginModal = () => {
    if (cookiesUtil.getCookieById('logged')) {
        return;
    }

    if (isAlimerkaOnlinePage()) {
        return;
    }

    if (isFolletoPage()) {
        return;
    }

    $('#loginmodal').fadeIn();
    $('#newoverlay').fadeIn();
};

/**
 * Function to return if user click on login modal or outside
 * @param {*} event with target element
 * @returns {boolean} true if is login modal
 */
const isSmallLoginModal = (event) => {
    return $(event.target).closest('.js-small-login-modal-form').length || $(event.target).is('.js-small-login-modal-form');
};

/**
 * Event to hide login modal and create again event to show modal
 * @param {*} event with target element
 */
const hideSmallLoginModal = (event) => {
    if (!isSmallLoginModal(event)) {
        $('.js-small-login-modal-form').css('display', 'none');

        $('body').off('click');
        /* eslint-disable no-use-before-define */
        $('.js-small-login-modal').on('mouseover', showSmallLoginModal);
    }
};

/**
 * Event to show login modal and create event to close modal
 */
const showSmallLoginModal = () => {
    $('.js-small-login-modal-form').css('display', 'block');
    $('.js-small-login-modal').off('mouseover');

    $('body').on('click', hideSmallLoginModal);
};

$(() => {
    showLoginModal();

    $('.js-small-login-modal').on('mouseover', showSmallLoginModal);

    $('.open-btn').on('click', (e) => {
        const modalId = $(e.currentTarget).data('modal');
        $('#' + modalId).fadeIn(0);
        $('#newoverlay').fadeIn(0);
    });

    $('.js-dark-modal').on('click', () => {
        $('.newmodal').addClass('dark');
    });

    $('.js-gold-modal').on('click', () => {
        $('.newmodal').addClass('gold');
    });

    $('.js-check-zipcode').on('click', function checkZipCodeModal(e) {
        e.stopPropagation();
        const $errorText = $('#zipcode-error');
        $errorText.addClass('d-none');
        const zipCode = $('input[name="zipCode"]').val();
        if (codigoPostalValido(zipCode)) {
            $errorText.text('').addClass('d-none').removeClass('d-block');
            const url = $(this).data('url') + '&zipCode=' + zipCode;
            $.spinner().start();
            $.ajax({
                url,
                type: 'get',
                dataType: 'json',
                success: (data) => {
                    if (data.success) {
                        $errorText.addClass('d-none');
                        cookiesUtil.setCookie('logged', true);
                        location.reload();
                    } else {
                        $.spinner().stop();
                        $errorText.text(data.msg).addClass('d-block');
                    }
                },
                error: (data) => {
                    $.spinner().stop();
                    $errorText.text(data.msg).addClass('d-block');
                }
            });
        } else {
            $errorText.text($errorText.data('message')).removeClass('d-none').addClass('d-block');
        }
    });

    // close modal
    $(document).on('click', '.close-btn', (e) => {
        $(e.currentTarget).parent('.newmodal').css('display', 'none');
    });
});
